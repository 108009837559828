import { ImageButtonCorvidModel } from '../ImageButton.types';

const entry: ImageButtonCorvidModel = {
  componentType: 'ImageButton',
  loadSDK: () =>
    import(
      './ImageButton.corvid' /* webpackMode: "eager" */ /* webpackChunkName: "ImageButton.corvid" */
    ),
};

export default entry;
