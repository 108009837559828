import {
  composeSDKFactories,
  withValidation,
} from '@wix/editor-elements-corvid-utils';
import {
  ICaptchaProps,
  ICaptchaSDK,
  ICaptchaOwnSDKFactory,
  CaptchaEventHandler,
} from '../Captcha.types';
import {
  focusPropsSDKFactory,
  hiddenPropsSDKFactory,
  collapsedPropsSDKFactory,
  elementPropsSDKFactory,
  toJSONBase,
} from '../../../core/corvid/props-factories';

const _ownSDKFactory: ICaptchaOwnSDKFactory = api => {
  const { props, compRef, registerEvent, metaData } = api;

  return {
    get token() {
      return props.token;
    },
    reset() {
      compRef.reset();
    },
    onError(handler: CaptchaEventHandler) {
      registerEvent('onError', handler);
    },
    onTimeout(handler: CaptchaEventHandler) {
      registerEvent('onTimeout', handler);
    },
    onVerified(handler: CaptchaEventHandler) {
      registerEvent('onVerified', handler);
    },
    toJSON() {
      const { token } = props;
      return {
        ...toJSONBase(metaData),
        token,
      };
    },
  };
};

const ownSDKFactory = withValidation(_ownSDKFactory, {
  type: ['object'],
  properties: {
    onError: {
      type: ['function'],
      args: [
        {
          type: ['function'],
        },
      ],
    },
    onTimeout: {
      type: ['function'],
      args: [
        {
          type: ['function'],
        },
      ],
    },
    onVerified: {
      type: ['function'],
      args: [
        {
          type: ['function'],
        },
      ],
    },
  },
});

export const sdk = composeSDKFactories<ICaptchaProps, ICaptchaSDK>(
  elementPropsSDKFactory,
  focusPropsSDKFactory,
  hiddenPropsSDKFactory,
  collapsedPropsSDKFactory,
  ownSDKFactory,
);
