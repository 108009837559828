import { IFallbackCorvidModel } from './Fallback.types';

const entry: IFallbackCorvidModel = {
  loadSDK: () =>
    import(
      './Fallback.corvid' /* webpackMode: "eager" */ /* webpackChunkName: "Fallback.corvid" */
    ),
};

export default entry;
