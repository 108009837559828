import {
  withValidation,
  assert,
  composeSDKFactories,
} from '@wix/editor-elements-corvid-utils';
import { GoogleMapSDKFactory } from '../GoogleMap.types';
import { elementPropsSDKFactory } from '../../../core/corvid/props-factories/elementPropsSDKFactory';
import { isLocationObject, areMarkersValid } from './validation';
import { getLinkObject } from './utils';

export const _googleMapSDKFactory: GoogleMapSDKFactory = ({
  setProps,
  props,
  linkUtils,
  compRef,
}) => ({
  get location() {
    const { mapData } = props;
    return {
      latitude: mapData.locations[0].latitude,
      longitude: mapData.locations[0].longitude,
      description: mapData.locations[0].title,
    };
  },

  set location(location) {
    const { mapData } = props;
    const newMapData = { ...mapData };

    newMapData.locations[0] = {
      ...mapData.locations[0],
      title: assert.isNil(location.description) ? '' : location.description,
      latitude: location.latitude || mapData.locations[0].latitude,
      longitude: location.longitude || mapData.locations[0].longitude,
    };

    setProps({ mapData: newMapData });
  },

  get markers() {
    const { mapData } = props;
    return mapData.locations.map(location => ({
      address: location.address,
      location: {
        longitude: location.longitude,
        latitude: location.latitude,
      },
      icon: location.pinIcon,
      link: assert.isObject(location.locationLinkAttributes)
        ? location.locationLinkAttributes.href
        : null,
      title: location.title,
      linkTitle: location.linkTitle,
      description: location.description,
    }));
  },

  set markers(markers) {
    const { mapData } = props;
    const newMapData = { ...mapData };

    newMapData.locations = markers.map(marker => {
      const resolvedLink = assert.isString(marker.link)
        ? getLinkObject(marker.link)
        : null;

      return {
        address: marker.address || '',
        latitude: marker.location?.latitude || NaN,
        longitude: marker.location?.longitude || NaN,
        title: marker.title || '',
        description: marker.description || '',
        link: resolvedLink,
        linkTitle: marker.linkTitle || '',
        pinIcon: marker.icon || '',
        pinColor: '',
        locationLinkAttributes: resolvedLink
          ? linkUtils.getLinkProps(resolvedLink.url, resolvedLink.target)
          : {},
      };
    });

    setProps({ mapData: newMapData });
  },

  setCenter({ longitude, latitude }) {
    if (!isLocationObject({ longitude, latitude })) {
      return;
    }

    return compRef.setMapCenter(longitude, latitude);
  },
});

const latitudeSchema = {
  type: ['number' as const],
  maximum: 90,
  minimum: -90,
  warnIfNil: true,
};

const longitudeSchema = {
  type: ['number' as const],
  maximum: 180,
  minimum: -180,
  warnIfNil: true,
};

const googleMapSDKFactory = withValidation(
  _googleMapSDKFactory,
  {
    type: ['object'],
    properties: {
      location: {
        type: ['object'],
        properties: {
          latitude: latitudeSchema,
          longitude: longitudeSchema,
          description: {
            type: ['string', 'nil'],
            warnIfNil: true,
          },
        },
      },
      setCenter: {
        type: ['function'],
        args: [
          {
            type: ['object'],
            properties: {
              latitude: latitudeSchema,
              longitude: longitudeSchema,
            },
          },
        ],
      },
    },
  },
  {
    location: [isLocationObject],
    markers: [areMarkersValid],
  },
);

export const sdk: GoogleMapSDKFactory = composeSDKFactories(
  elementPropsSDKFactory,
  googleMapSDKFactory,
);
